import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Box } from "@mui/material";

const PageContainer = ({ title, description, children }) => (
  <>
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
    <Box
      display="flex"
      flexDirection="column"
      gap={3}
      sx={{
        padding: 2,
        backgroundColor: "#f9f9f9",
        borderRadius: 2,
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      {children}
    </Box>
  </>
);

PageContainer.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
};

export default PageContainer;
