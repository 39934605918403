import { createSlice } from "@reduxjs/toolkit";
import { setSession } from "../utils/jwt";
import { getCurrentUser, getUsers, loginUser } from "./actions/userActions";

const userSlice = createSlice({
  name: "user",
  initialState: {
    login: {
      loading: false,
      loggedIn: false,
      user: null,
    },
    users: {
      loading: false,
      data: null,
    },
    currentUser: {
      loading: false,
      data: null,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state, action) => {
      state.login.loading = true;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.login.loading = false;
      state.login.loggedIn = true;
      state.login.user = action.payload;
      setSession(action.payload);
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.login.loading = false;
    });
    builder.addCase(getUsers.pending, (state, action) => {
      state.users.loading = true;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.users.loading = false;
      state.users.data = action.payload;
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.users.loading = false;
    });
    builder.addCase(getCurrentUser.pending, (state, action) => {
      state.currentUser.loading = true;
    });
    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      state.currentUser.loading = false;
      state.currentUser.data = action.payload;
    });
    builder.addCase(getCurrentUser.rejected, (state, action) => {
      state.currentUser.loading = false;
    });
  },
});

export default userSlice.reducer;
